import { useState, useEffect } from "react";
import { Box, Typography, Button, } from "@mui/material";

import Axios from "axios";
import { tokens } from "./theme";
const host = "";
function Test({id, imageType}) {
    const colors = tokens;
    const [selectedFile, setSelectedFile] = useState();
	const [error, setError] = useState("");
	const [showerr, setShowerr] = useState(true);
	const [respImg, setRespImg] = useState();

	
    const handleUpload = () => {
		
        if (selectedFile) {
			const data = new FormData();

			data.append("file", selectedFile);
			data.append('id', id);
			data.append('imageType', imageType);

			const url = host + "/uploadImg";

			Axios.post(url, data, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}).then((response) => {
				// console.log(response.data);
				if (response.data === "success") {
					setError("uploaded");
			        setShowerr(true);
				}
			});
		} else {
			setError(
				"No file selected"
			);
			setShowerr(true);
		}
	};

	 const handleDelete = () => {
		
       const data = {
				id: id,
				imageType:imageType
			};

			const url = host + "/deleteImg";
			Axios.post(url, data).then((response) => {
				// console.log(response.data)
				if(response.data===""){
					setRespImg("")
					setError(
						"DELETED"
					);
					setShowerr(true);
						}
				// else setRespImg('');
			});
	
	};

	const style = {
		width: "100%",
		height: "auto",
		bgcolor: colors.primary[400],
		borderRadius: 1,
		display: "flex",	
		alignItems:"center",
		color: colors.blueAccent[111],	
	
	};


	useEffect(() => {
    const getImg = () => {

			const data = {
				id: id,
				imageType:imageType
			};

			const url = host + "/getImg";
			Axios.post(url, data, { responseType: "blob" }).then((response) => {
				if(response.data!==""){
					const file = window.URL.createObjectURL(
						new Blob([response.data], { type: "image/jpeg" })
					);
					// window.open(file);
					setRespImg(file);
				}
				else setRespImg('');
			});
	
	};
	getImg()
  }, [error]);

   
  return (


					<Box sx={style}>
				 <img
					src={respImg}
					width="150px"
					alt="no image"/>

							<input
								type="file"
								name="import-img"
								accept="image/png, image/gif, image/jpeg"
								onChange={(e) => {
									setSelectedFile(e.target.files[0]);
									setShowerr(false);
								}}
								// hidden
							/>
	
						{showerr ? (
							<Box m="auto">
								<Typography
									variant="h6"
									color={colors.grey[500]}
									fontWeight="bold"
								>
									{error}{" "}
								</Typography>
							</Box>
						) : (
							""
						)}
						<Button
							variant="contained"
					
							sx={{
								"background": colors.blueAccent[111],
								"height": "20px",
								":hover": { bgcolor: colors.blueAccent[300] },
								"overflow":"hidden",
								"marginLeft":"10px",
								"marginRight":"10px",

							}}
							onClick={handleUpload}
						>upload</Button>
						<Button
							variant="contained"
					
							sx={{
								"background": colors.redAccent[500],
								"height": "20px",
								":hover": { bgcolor: colors.redAccent[300] },
								"overflow":"hidden",
								"marginLeft":"10px",
								"marginRight":"10px",

							}}
							onClick={handleDelete}
						>delete</Button>
					</Box>
				
  );
}

export default Test;