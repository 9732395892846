import {useState, useEffect} from "react";
import "./Header.css";
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import Axios from "axios";
import { tokens } from "./useTheme";


const host = ""
function Header() {
  const isNonMobile = useMediaQuery("(max-width:600px)");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [logoImg, setLogoImg] = useState(false);
  const [theme, setTheme] = useState('')
  const colors = tokens(theme?.id===1?'dark':'light');

  useEffect(() => {
    isNonMobile ? setIsCollapsed(true) : setIsCollapsed(false);
  }, [isNonMobile]);

  useEffect(() => {
    const getLogo = (id) => {

			const data = {
				id: id,
				imageType:'logo'
			};

			const url = host + "/getThemeImg";
			Axios.post(url, data, { responseType: "blob" }).then((response) => {
				if(response.data!==""){
					const file = window.URL.createObjectURL(
						new Blob([response.data], { type: "image/jpeg" })
					);
					setLogoImg(file);
				}

			});
	
	};
  const fetchTheme = ()=>{
    const url = host + "/fetchTheme";
    Axios.get(url).then((response) => {
      if(response.data[0].status==='active'){
        setTheme(response.data[0])
        getLogo(response.data[0].id)
      }
      else {
        setTheme(response.data[1])
        getLogo(response.data[1].id)
      
      }
      

    })
  }
  fetchTheme()
  }, []);

  return (
    <div>
    <nav className="header" style={{backgroundColor:colors.themeMain[100]}}>
        {isCollapsed? <span className="navlinks__left__menu2 navlink__link2">
            <MenuIcon />
          </span>:""}
        

        <Link to="/">
          <img
            src={logoImg}
            alt="logo"
            className="header__logo"
          />
        </Link>

        {isCollapsed?"":<div className="header__search" style={{backgroundColor:colors.themeMain[100]}}>
          <input type="text" placeholder="Search for products, brands and more" name="" id="" className="header__searchbar" />
          <SearchIcon className="header__searchIcon" style={{backgroundColor:colors.buttonMain[100], color:colors.themeMain[100]}}/>
        </div>}
      
        <div className="header__nav">       
          <Link to="" className="header__link">
            <div className="header__option">
              <span className="header__option__lineOne">Returns</span>
              <span className="header__option__lineTwo">& Orders</span>
            </div>
          </Link>
          <Link to="" className="header__link">
            <div className="header__option" >
              <span className="header__option__lineOne">Your</span>
              <span className="header__option__lineTwo">Prime</span>
            </div>
          </Link>
          <Link to="/checkout" className="header__link">
            <div className="header__option__basket">
              <ShoppingCartOutlinedIcon style={{ fontSize: 30 }} />
              <span className="header__option__lineTwo header__basket__count">
              </span>
            </div>
          </Link>
      </div>
      
    </nav>
    {isCollapsed?<div className="header_2" style={{backgroundColor:colors.themeMain[100]}}>
              <input type="text" placeholder="Search for products, brands and more" name="" id="" className="header__searchbar2" />
              <SearchIcon className="header__searchIcon" style={{backgroundColor:colors.buttonMain[100], color:colors.themeMain[100]}}/>
            </div>:""}
            </div>

  );
}

export default Header;
