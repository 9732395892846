import Switch from "@mui/material/Switch";
import { useState, useEffect  } from "react";
import { alpha, styled } from "@mui/material/styles";
import Axios from "axios";
import { Box, Button, IconButton } from "@mui/material";
import { tokens } from "./theme";
import { DataGrid } from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EditTheme from "./EditTheme";
const host = ""
function Profile() {
   const colors = tokens;
    const [showerr, setShowerr] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [theme, setTheme] = useState(0);
  	const BlueSwitch = styled(Switch)(({ theme }) => ({
        "& .MuiSwitch-switchBase.Mui-checked": {
          color: colors.blueAccent[333],
          "&:hover": {
            backgroundColor: alpha(
              colors.blueAccent[333],
              theme.palette.action.hoverOpacity
            ),
          },
        },

        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
          backgroundColor: colors.blueAccent[333],
        },
      }));
      const handleClose = () => {
		
		setOpenEdit(false);
	};
  const handleChange = () => {
    setShowerr(true);
    let data ={}
    if(theme[0]['status']==='active'){
      data = {
          a: '',
          b: 'active'
        }
      }
      else {
        data = {
          a: 'active',
          b: ''
        }
      }
      const url = host + "/updateTheme";
      Axios.post(url, data).then((response) => {        
        const url = host + "/fetchTheme";
        Axios.get(url).then((response) => {

          setTheme(response.data);
          setShowerr(false)
			});
	
    })

		
	};


  const columns = [
		{ field: "name", headerName: "Name" },
		{ field: "logo", headerName: "Logo" },
		{ field: "banner", headerName: "banner" },
    { field: "status", headerName: "status" },
    {
				field: "edit",
				headerName: "Edit",

				renderCell: (params) => {
					return (
						<IconButton
							onClick={() => {
								setOpenEdit(true);
								setSelectedRows(params.row);
							}}
						>
							<EditOutlinedIcon />
						</IconButton>
					);
				},
		},
    {
				field: "activate",
				headerName: "Activate",

				renderCell: (params) => {
					return (
						<BlueSwitch
						checked={params.row.status==='active'}
						onChange={()=>{handleChange()}}
						sx={{
							ml: "5px",
						}}
					/>
					);
				},
		},
  ]

 const style1 = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
    minWidth:"320px",
		width: "auto",
		height: "auto",
    padding:'30px',
		background: colors.grey[901],
		borderRadius: 2,
		display: "grid",
		columnGap: 1,
		rowGap: 1,
		gridTemplateColumns: "repeat(1, 1fr)",
		p: 4,
		m: 0,
	};

    useEffect(() => {
		const fetchTheme = async () => {
			const url = host + "/fetchTheme";
			Axios.get(url).then((response) => {
				setTheme(response.data);
			});
		};
		fetchTheme();
	}, []);
  const style = {
		
		width: "100%",
		height: "100%",
		bgcolor: colors.primary[400],
		borderRadius: 1,
		display: "grid",
		gridTemplateColumns: "repeat(1, 1fr)",
		gridTemplateRows: "repeat(10, 1fr)",
		color: colors.blueAccent[800],
		boxShadow: 4,
		p: 4,
		m: 0,
	};

  return (
   <Box sx={style}>
      <Box
          height="auto"
          width="auto"
          sx={{
            borderRadius: "4px",
            boxShadow: 4,
            "& .MuiDataGrid-root": {
              border: "none",
            },
        
            "& .name-column--cell": {
              color: colors.greenAccent[300],
              backgroundColor: "white",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.themeBlue[900],
              borderBottom: "none",

              fontSize: "16px",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.grey[901],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.themeBlue[900],
            },
          }}
        >
          <DataGrid
            rows={theme}
            columns={columns}
            getRowId={(row) => row.id}
            // hideFooter
            disableColumnMenu
            getRowHeight={() => "30px"}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}

            onRowSelectionModelChange={(ids) => {
            	const selectedIDs = new Set(ids);

            	const selectedRow = theme.filter((row) =>
            		selectedIDs.has(row.id)
            	);

            	setSelectedRows(selectedRow[0]);
            }}
          />
        </Box>
         <Modal
				open={showerr}
				// onClose={handleClose}
				style={{ backdropFilter: "blur(2px)" }}
			>
				<Box style={style1}>
          <h3 style={{margin:'auto'}}>Updating...</h3>
				</Box>
			</Modal>

      <Modal
				open={openEdit}
				onClose={handleClose}
				style={{ backdropFilter: "blur(2px)" }}
			>
				<Box>
					<EditTheme colors={colors} row = {selectedRows} />
				</Box>
			</Modal>
    </Box>
  );
}

export default Profile;