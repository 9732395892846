import "./Home.css";
import Product from "./Product";
import CountdownTimer from "./countdown"
import Axios from "axios";
import { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "./useTheme";
const host = ""
function Home() {

const isNonMobile = useMediaQuery("(max-width:600px)");
const [isCollapsed, setIsCollapsed] = useState(false);
const [theme, setTheme] = useState('')
const [bannerImg, setBannerImg] = useState(false);
const colors = tokens(theme?.id===1?'dark':'light');
useEffect(() => {
    isNonMobile ? setIsCollapsed(true) : setIsCollapsed(false);
  }, [isNonMobile]);

  useEffect(() => {
    const getLogo = (id) => {

			const data = {
				id: id,
				imageType:'banner'
			};

			const url = host + "/getThemeImg";
			Axios.post(url, data, { responseType: "blob" }).then((response) => {
				if(response.data!==""){
					const file = window.URL.createObjectURL(
						new Blob([response.data], { type: "image/jpeg" })
					);
					setBannerImg(file);
				}

			});
	
	};
  const fetchTheme = ()=>{
    const url = host + "/fetchTheme";
    Axios.get(url).then((response) => {
      if(response.data[0].status==='active'){
        setTheme(response.data[0])
        getLogo(response.data[0].id)
      }
      else {
        setTheme(response.data[1])
        getLogo(response.data[1].id)
      
      }
      

    })
  }
  fetchTheme()
  }, []);




  const [products, setProducts] = useState([]);
  useEffect(() => {
		const fetchProducts = async () => {
			const url = host + "/fetchProducts";
			Axios.get(url).then((response) => {
				setProducts(response.data);
        // console.log(response)
			});
		};
		fetchProducts();
	}, []);
  return (
    <div className="home">
      <img
        className="home__image"
        src={bannerImg}
        alt="amazon_home"
      />
      <div style={{margin:'auto, 30px', height:'60px', display:'grid', marginBottom:"10px" }} >
        <span style={{margin:'auto', fontWeight:'bold', fontSize:'18px'}}>Today's Deals:</span> <CountdownTimer/>
      </div>
      <div className={isCollapsed?"home__row__mobile":"home__row"}>
        {
        Array.isArray(products)
        ?products.map((row)=>{
          // console.log(row)
          return(<Product
            key = {row.id}
            row = {row}
            isCollapsed = {isCollapsed}
          />)
        })
        :null
      }

      </div>
    </div>
  );
}

export default Home;
