import {
	Button,
	Typography,
	TextField,
	Box,
} from "@mui/material";
import Axios from "axios";
import { useState } from "react";
import ThemeImage from "./ThemeImage"

function EditTheme({colors, row}) {

    const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "50%",
		height: "auto",
		background: colors.grey[901],
		borderRadius: 2,
		display: "grid",
		columnGap: 1,
		rowGap: 1,
		gridTemplateColumns: "repeat(1, 1fr)",
		p: 4,
		m: 0,
	};


  return (
    <Box sx={style}>
		Logo Image:<ThemeImage id = {row.id} imageType = "logo" />
		Banner Image:<ThemeImage id = {row.id} imageType = "banner" />
       
        
	</Box>
  );
}

export default EditTheme;