import "./Footer.css";
import {useState, useEffect} from "react";
import ScrollUpButton from "react-scroll-up-button";
import useMediaQuery from "@mui/material/useMediaQuery";
import Axios from "axios";
const host = "";
const Footer = () => {
  const isNonMobile = useMediaQuery("(max-width:600px)");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [theme, setTheme] = useState('')
  useEffect(() => {
    isNonMobile ? setIsCollapsed(true) : setIsCollapsed(false);
  }, [isNonMobile]);

  useEffect(() => {

  const fetchTheme = ()=>{
    const url = host + "/fetchTheme";
    Axios.get(url).then((response) => {
      if(response.data[0].status==='active'){
        setTheme(response.data[0])       
      }
      else {
        setTheme(response.data[1])      
      }
      

    })
  }
  fetchTheme()
  }, []);
  
  return (
    <footer className="footer">
      <div className="footer__backToTop">
        <ScrollUpButton />
        TOP OF PAGE
      </div>

      <div className="footer__linkContainer">

 {isCollapsed?"":    
         <div className="footer__links">
          <h3 className="footer__linksTitle">Get to Know Us</h3>
          <p className="footer__link">About Us</p>
          <p className="footer__link">Careers</p>
          <p className="footer__link">Press Releases</p>
          <p className="footer__link">{theme?.id===1?"Amazon":"Flipcart"} Cares</p>
          <p className="footer__link">Gift a Smile</p>
        </div>}
        
        {isCollapsed?"":     

        <div className="footer__links">
          <h3 className="footer__linksTitle">Connect with Us</h3>
          <p className="footer__link">Facebook</p>
          <p className="footer__link">Twitter</p>
          <p className="footer__link">Instagram</p>        
        </div>}
        
        <div className="footer__links">
          <h3 className="footer__linksTitle">Make Money with Us</h3>
          <p className="footer__link">Sell on {theme?.id===1?"Amazon":"Flipcart"}</p>
          <p className="footer__link">Sell under {theme?.id===1?"Amazon":"Flipcart"} Accelerator</p>
          <p className="footer__link">{theme?.id===1?"Amazon":"Flipcart"} Global Selling</p>
          <p className="footer__link">Become an Affiliate</p>
          <p className="footer__link">{theme?.id===1?"Amazon":"Flipcart"} Pay on Merchants</p>
          <p className="footer__link">Advertise Your Products</p>
        </div>
        <div className="footer__links">
          <h3 className="footer__linksTitle">Let Us Help You</h3>
          <p className="footer__link">COVID-19 and {theme?.id===1?"Amazon":"Flipcart"}</p>
          <p className="footer__link">Your Account</p>
          <p className="footer__link">Returns Centre</p>
          <p className="footer__link">100% Purchase Protection</p>
          <p className="footer__link">{theme?.id===1?"Amazon":"Flipcart"} App Download</p>
          <p className="footer__link">Help</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
