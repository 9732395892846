import {
	Button,
	Typography,
	TextField,
	Box,
} from "@mui/material";
import Axios from "axios";
import { useState } from "react";
function EditProduct({colors, row}) {

	const [showerr, setShowerr] = useState(false);
    const [error, setError] = useState("");
	const updateProduct = (e) =>{

		e.preventDefault();

		function createObjectFromData(data) {
                    const keyValuePairs = data.split('\n')
                        .map(line => line.split('\t'))
                        .map(([key, value]) => [key.trim(), value.trim()]);

                    const object = {};

                    for (const [key, value] of keyValuePairs) {
                        object[key] = value;
                    }

                    return object;
                    }
					let tech = ""
		if(e.target.tech_details.value!==""){	
			if(e.target.tech_details.value===row.tech_details){
				tech = e.target.tech_details.value
			}		
			else {const result = createObjectFromData(e.target.tech_details.value);
			 tech = JSON.stringify(result)}
			}
			else tech = ""
			// console.log(tech);
		// console.log(result);
		const data = {
			id:row.id,
			product_name: e.target.product_name.value,
			rating: e.target.rating.value,
			mrp: e.target.mrp.value,
			price: e.target.price.value,
			tech_details: tech,
		}
		Axios.post("/updateProduct", data).then((response) => {
			if (response.data === "error") {
				setError("ERROR! Something went wrong.");
				setShowerr(true);
			} else if (response.data === "success") {
				window.location.reload(true);
			}
		});

	}

    const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "50%",
		height: "auto",

		background: colors.grey[901],
		borderRadius: 2,
		display: "grid",
		columnGap: 1,
		rowGap: 1,
		gridTemplateColumns: "repeat(1, 1fr)",
		boxShadow: 4,
		p: 4,
		m: 0,
	};

  return (
	<form onSubmit={updateProduct}>
   		<Box sx={style}>
			<Typography
							color={colors.blueAccent[222]}
							variant="h5"
							fontWeight="bold"
							sx={{ mb: "5px" }}
						>
				Edit Product
			</Typography>
			<TextField variant="filled" id="product_name" label="Product Name" 
				defaultValue={row? row.product_name : ""} />					
			<TextField type="number" variant="filled" id="rating" label="Rating" 
				InputProps={{inputProps:{min:0, max:5}}} 
				defaultValue={row? row.rating : ""}/>
			<TextField variant="filled" id="mrp" label="MRP" 
				defaultValue={row? row.mrp : ""}/>
			<TextField variant="filled" id="price" label="Price" 
				defaultValue={row? row.price : ""}/>
				Technical Details:
			<textarea id="tech_details" label="Technical Details" 
				/>
			<Button
				variant="contained"
				type="submit"
				sx={{
					"background": colors.blueAccent[222],
					"height": "50px",

					":hover": { bgcolor: colors.themeBlue[100] },
				}}
			>
				Update Product
			</Button>
			{showerr ? (
					<Typography
						variant="h6"
						color={colors.grey[500]}
						fontWeight="bold"
					>
						{error}
					</Typography>
				) : (
					""
				)}
		</Box>
	</form>
  );
}

export default EditProduct;