import Header from "./Header";
import { useState, useEffect } from "react";
import Axios from "axios";
import primeImg from "./Images/prime11.png"
import fa from "./Images/fa.png"
import shield from "./Images/shield.png"
import deliveryImg from "./Images/delivered.png"
import loveImg from "./Images/love.png"
import replaceImg from "./Images/returns.png"
import { Button, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Product.css";
import Carousel from "nuka-carousel"
import CountdownTimer from "./countdown"
const host = ""


function ProductPage() {

    const [theme, setTheme] = useState('')
    const [product, setProduct] = useState([]);
    const [techDetails, setTechDetails] = useState([]);
    const [mainImg, setMainImg] = useState();
    const [car_img1, setCar_img1] = useState();
    const [car_img2, setCar_img2] = useState();
    const [car_img3, setCar_img3] = useState();
    const [car_img4, setCar_img4] = useState();
    const [bannerImg, setBannerImg] = useState();

    const navigate = useNavigate();

        
    useEffect(() => {
        window.scrollTo(0, 0)
        const getproduct = async () => {

        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get("id");
        
        const data = {
            id: id,
        };
        const url = host + "/getproduct";
        Axios.post(url, data).then((response) => {
            if (response.data === "fail") {
            setProduct('');
            } else {
                if(response.data.tech_details!==""){
            setTechDetails(JSON.parse(response.data.tech_details));
            }

            setProduct(response.data);
            if(response.data.main_img !== ""){
                const data = {
				id: response.data.id,
				imageType:"main_img"
			    };
                const url = host + "/getImg";
			Axios.post(url, data, { responseType: "blob" }).then((response) => {
				if(response.data!==""){
					const file = window.URL.createObjectURL(
						new Blob([response.data], { type: "image/jpeg" })
					);
					setMainImg(file);
				}
			});

            }
            if(response.data.car_img1 !== ""){
                const data = {
				id: response.data.id,
				imageType:"car_img1"
			    };
                const url = host + "/getImg";
			Axios.post(url, data, { responseType: "blob" }).then((response) => {
				if(response.data!==""){
					const file = window.URL.createObjectURL(
						new Blob([response.data], { type: "image/jpeg" })
					);
					setCar_img1(file);
				}
			});

            }

            if(response.data.car_img2 !== ""){
                const data = {
				id: response.data.id,
				imageType:"car_img2"
			    };
                const url = host + "/getImg";
			Axios.post(url, data, { responseType: "blob" }).then((response) => {
				if(response.data!==""){
					const file = window.URL.createObjectURL(
						new Blob([response.data], { type: "image/jpeg" })
					);
					setCar_img2(file);
				}
			});
            }

            if(response.data.car_img3 !== ""){
                const data = {
				id: response.data.id,
				imageType:"car_img3"
			    };
                const url = host + "/getImg";
			Axios.post(url, data, { responseType: "blob" }).then((response) => {
				if(response.data!==""){
					const file = window.URL.createObjectURL(
						new Blob([response.data], { type: "image/jpeg" })
					);
					setCar_img3(file);
				}
			});
            }

            if(response.data.car_img4 !== ""){
                const data = {
				id: response.data.id,
				imageType:"car_img4"
			    };
                const url = host + "/getImg";
			Axios.post(url, data, { responseType: "blob" }).then((response) => {
				if(response.data!==""){
					const file = window.URL.createObjectURL(
						new Blob([response.data], { type: "image/jpeg" })
					);
					setCar_img4(file);
				}
			});
            }

            if(response.data.banner_img !== ""){
                const data = {
				id: response.data.id,
				imageType:"banner_img"
			    };
                const url = host + "/getImg";
			Axios.post(url, data, { responseType: "blob" }).then((response) => {
				if(response.data!==""){
					const file = window.URL.createObjectURL(
						new Blob([response.data], { type: "image/jpeg" })
					);
					setBannerImg(file);
				}
			});
            }


            }
        });
        };
        getproduct();
  }, []);

  useEffect(() => {

  const fetchTheme = ()=>{
    const url = host + "/fetchTheme";
    Axios.get(url).then((response) => {
      if(response.data[0].status==='active'){
        setTheme(response.data[0])       
      }
      else {
        setTheme(response.data[1])      
      }
      

    })
  }
  fetchTheme()
  }, []);

  return (
    <div >
      <Header />
      <div style={{display:'flex', justifyContent:'space-between', width:'100%',  padding:'20px 20px 20px 20px'}} >
      <Box sx = {{
                //   position:'absolute',
                  background:"#B12704",
                  borderRadius:'100%', 
                  display:'flex',
                  alignContent:'center',
                  width:'42px',
                  padding:'2px',
                  top:'5%',
                  left:'4%'}}>
                    <Typography sx = {{fontSize:'12px',
                                        fontWeight:'bold',
                                        color:'white',
                                        padding:'2px',
                                        margin:'auto',
                                        }}>
                    {Math.trunc(100-product?.price*100/product?.mrp)}%<br/>OFF
                    </Typography>
          </Box>
        <Box sx = {{
                //   position:'absolute',
                //   zIndex:100,
                  borderRadius:'100%', 
                  display:'flex',
                  alignContent:'center',
                  width:'42px',
                  padding:'2px',
                  top:'5%',
                  right:'4%'}}>
                    <img
            width="60px"
            src={loveImg}
            alt=""
             />
          </Box>
          </div>
      <div style={{margin:'auto', width:'300px', marginTop:'10px'}}>
        
        <Carousel  slideWidth="300px" >
                <img width='300px' src={mainImg} />
                {car_img1?<img width='300px' src={car_img1}/>:""}
                {car_img2?<img width='300px' src={car_img2}/>:""}
                {car_img3?<img width='300px' src={car_img3}/>:""}
                {car_img4?<img width='300px' src={car_img4}/>:""}
            </Carousel>
        </div>
       <div className="product">
        

          <div className="product__info">
            <p className="product_title" style={{marginTop:'30px'}}>{product?.product_name}</p>
            <p className="product__price">
                MRP: <small>₹</small>
                <strike>{product?.mrp}</strike>
                
                </p>
            <Typography sx = {{fontSize:'14px',
                                        fontStyle:'italic',
                                        color:'red',
                                        padding:'2px',
                                        margin:'auto',
                                        }}> Save&nbsp;<small>₹</small>
                    {(product?.mrp - product?.price)} 
                    </Typography>
            <img
            width="60px"
            src={theme?.id===1?primeImg:fa}
            alt=""
             />
            <p className="product__price">
            Price: <small>₹</small>
            <strong>{product?.price}</strong>
            </p>
            

          
            <CountdownTimer/>
       
        </div>
        <div style={{display:'flex'}}>
            <div style={{display:'flex', flexDirection:"column", alignContent:"center", marginRight:'10px'}}>
                <img
                width="60px"                
                src={replaceImg}
                alt=""
                style={{margin:'auto'}}
                />
                <h6 style={{margin:'auto', color:"grey"}}>7 days Replacement</h6>
             </div>
             <div style={{display:'flex', flexDirection:"column", alignContent:"center", marginRight:'10px'}}>
                <img
                width="60px"
                src={deliveryImg}
                alt=""
                style={{margin:'auto'}}
                />
                <h6 style={{margin:'auto', color:"grey"}}>{theme?.id===1?"Amazon":"Flipcart"} delivered</h6>
              </div>
             <div style={{display:'flex', flexDirection:"column", alignContent:"center", marginRight:'10px'}}>
                <img
                width="48px"
                src={shield}
                alt=""
                style={{margin:'auto'}}
                />
                <h6 style={{margin:'auto', color:"grey"}}>1 year warranty</h6>
              </div>
             
        </div>
        
        </div>

        <div style={{height:'40px',  backgroundColor:"#F0F2F2", alignItems:'center'}}>
            <h2 style={{marginLeft:"25px", alignSelf:'center'}}>Features & Details:</h2>
        </div>

        <div style={{margin:'25px'}}>
            <h3 style={{color:"rgb(251, 100, 27)"}}>Product information</h3><br/>
            <h5>Technical Details</h5>
            <table style={{borderCollapse:'collapse', borderSpacing:"0"}}>
                <tbody>
               

                    {
                    Object.keys(techDetails).map( (key) =>{
                        // // console.log("k",key, "v",values)
                        // console.log(row)
                        // return ""
                        return  <tr>
                                     <th style={{width:"50%", whiteSpace:"normal", wordWrap:"break-word", padding:"7px 14px 6px",
                                    borderTop:"1px solid #BBBFBF", backgroundColor:"#F0F2F2", fontSize:"14px", lineHeight:"20px!important",
                                    color:"#565959!important", lineHeight:"20px!important", textAlign:'left'}}>
                                        {key}
                                    </th>
                                    <td style={{ whiteSpace:"normal", wordWrap:"break-word", padding:"7px 14px 6px",
                                    borderTop:"1px solid #BBBFBF", fontSize:"14px", lineHeight:"20px!important",
                                    color:"#333!important", lineHeight:"20px!important", textAlign:'left'}}>
                                        {techDetails[key]}
                                    </td>
                                </tr>
                    })
                
                
                }
                   
                </tbody>
            </table>
        </div>

          <div style={{width:'60%', height:'400px', overflow:'hidden', margin:'75px', marginLeft:'50px'}}>
          {bannerImg?<img style={{maxWidth:'100%', maxHeight:'100%', width:'100%', overflow:'hidden'}} src={bannerImg}/>:""}
          </div>
        <div style={{position:'fixed',left:'0%', bottom:'0%', width:'100%', height:'50px',
             overflow:'hidden', zIndex:'50'}}>
            <Button sx={{height:"100%", backgroundColor:"#f0f0eb", 
                        color:'black', width:'50%', ":hover": { bgcolor: "#f0f0eb" }}} onClick={() => {
										navigate(`/address?id=${product.id}`);
									}}> Add to Cart</Button>
            <Button sx={{height:"100%", backgroundColor:"rgb(251, 100, 27)", color:'white', 
                        width:'50%' , ":hover": { bgcolor: "#eca63d" }}} onClick={() => {
										navigate(`/address?id=${product.id}`);
									}}>Buy Now</Button>
        </div>
    </div>
  );
}

export default ProductPage;