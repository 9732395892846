import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import Axios from "axios";
import { tokens } from "./theme";
import { Box, Button, IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import AddProduct from "./AddProduct"
import EditProduct from "./EditProduct"
import AddImg from "./AddImg"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ImageIcon from '@mui/icons-material/Image';
import DeleteProduct from "./DeleteProduct";
const host = ""
function Manage() {
  const colors = tokens;
  const [products, setProducts] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [openImg, setOpenImg] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

	const [openAdd, setOpenAdd] = useState(false);

  const columns = [
		{ field: "id", headerName: "ID" },
		{ field: "product_name", headerName: "Product Name" },
		{ field: "rating", headerName: "rating" },
		{ field: "mrp", headerName: "mrp" },
		{ field: "price", headerName: "price" },
		{ field: "tech_details", headerName: "Tech. Details" },
		{
				field: "images",
				headerName: "Images",

				renderCell: (params) => {
					return (
						<IconButton
							onClick={() => {
								setOpenImg(true);
								setSelectedRows(params.row);
							}}
						>
							<ImageIcon />
						</IconButton>
					);
				},
		},
		{
				field: "edit",
				headerName: "Edit",

				renderCell: (params) => {
					return (
						<IconButton
							onClick={() => {
								setOpenEdit(true);
								setSelectedRows(params.row);
							}}
						>
							<EditOutlinedIcon />
						</IconButton>
					);
				},
		},
		{
				field: "delete",
				headerName: "Delete",

				renderCell: (params) => {
					return (
						<IconButton
							onClick={() => {
								setOpenDelete(true);
								setSelectedRows(params.row);
							}}
						>
							<DeleteForeverOutlinedIcon />
						</IconButton>
					);
				},
		}
	];

  const handleClose = () => {
		setOpenAdd(false);
		setOpenImg(false);
		setOpenEdit(false);
		setOpenDelete(false);
	};
  const style = {
		
		width: "100%",
		height: "100%",
		bgcolor: colors.primary[400],
		borderRadius: 1,
		display: "grid",
		gridTemplateColumns: "repeat(1, 1fr)",
		gridTemplateRows: "repeat(1, 1fr)",
		color: colors.blueAccent[800],
		boxShadow: 4,
		p: 4,
		m: 0,
	};

  useEffect(() => {
		const fetchProducts = async () => {
			const url = host + "/fetchProducts";
			Axios.get(url).then((response) => {
				setProducts(response.data);
			});
		};
		fetchProducts();
	}, []);

  return (
    <Box sx={style}>
      <Button
					variant="contained"
					sx={{
						background: colors.blueAccent[222],
						height: "40px",
						width:'200px',
						margin:'auto',
						marginBottom:'20px',

						":hover": { bgcolor: colors.themeBlue[100] },
					}}
					onClick={() => setOpenAdd(true)}
				>Add Product
				</Button>

      <Box
          height="auto"
          width="auto"
          sx={{
            borderRadius: "4px",
            boxShadow: 4,
            "& .MuiDataGrid-root": {
              border: "none",
            },
        
            "& .name-column--cell": {
              color: colors.greenAccent[300],
              backgroundColor: "white",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.themeBlue[900],
              borderBottom: "none",

              fontSize: "16px",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.grey[901],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.themeBlue[900],
            },
          }}
        >
          <DataGrid
            rows={products}
            columns={columns}
            getRowId={(row) => row.id}
            // hideFooter
            disableColumnMenu
            getRowHeight={() => "30px"}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}

            onRowSelectionModelChange={(ids) => {
            	const selectedIDs = new Set(ids);

            	const selectedRow = products.filter((row) =>
            		selectedIDs.has(row.id)
            	);

            	setSelectedRows(selectedRow[0]);
            }}
          />
        </Box>
        <Modal
				open={openAdd}
				onClose={handleClose}
				style={{ backdropFilter: "blur(2px)" }}
			>
				<Box>
					<AddProduct colors={colors} />
				</Box>
			</Modal>
      <Modal
				open={openEdit}
				onClose={handleClose}
				style={{ backdropFilter: "blur(2px)" }}
			>
				<Box>
					<EditProduct colors={colors} row = {selectedRows} />
				</Box>
			</Modal>
      <Modal
				open={openDelete}
				onClose={handleClose}
				style={{ backdropFilter: "blur(2px)" }}
			>
				<Box>
					<DeleteProduct colors={colors} row = {selectedRows} />
				</Box>
			</Modal>
		<Modal
				open={openImg}
				onClose={handleClose}
				// style={{ backdropFilter: "blur(2px)" }}
			>
				<Box>
					<AddImg colors={colors} row = {selectedRows} />
				</Box>
			</Modal>
    </Box>
  );
}

export default Manage;