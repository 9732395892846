import { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, Typography } from "@mui/material";
import { tokens } from "./theme";
import useAuth from "./useAuth";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link, useNavigate } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import HomeIcon from '@mui/icons-material/Home';
import Person2Icon from '@mui/icons-material/Person2';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddBoxIcon from '@mui/icons-material/AddBox';
import LockIcon from '@mui/icons-material/Lock';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
const Item = ({ title, to, icon, selected, setSelected }) => {
	return (
		<MenuItem
			active={selected === to}
			onClick={() => setSelected(to)}
			icon={icon}
			component={<Link to={to} />}
		>
			<Typography
				sx={{
					fontSize: "12px",
				}}
			>
				{title}
			</Typography>
		</MenuItem>
	);
};

const SideNavBar = ({ selected, setSelected }) => {
	const colors = tokens;
	const navigate = useNavigate();
	const { logout } = useAuth();
	const isNonMobile = useMediaQuery("(max-width:600px)");
	const [isCollapsed, setIsCollapsed] = useState(false);
	useEffect(() => {
		isNonMobile ? setIsCollapsed(true) : setIsCollapsed(false);
	}, [isNonMobile]);

	return (
		<Box height="100vh">
			<Sidebar collapsed={isCollapsed} width="200px">
				<Menu
					iconShape="square"
					menuItemStyles={{
						button: {
							// the active class will be added automatically by react router
							// so we can use it to style the active menu item
							[`&.ps-active`]: {
								backgroundColor: colors.blueAccent[333],
								color: colors.blueAccent[900],
							},
						},
					}}
				>
					{/* LOGO AND MENU ICON */}
					<MenuItem
						icon={
							isCollapsed ? (
								<MenuOutlinedIcon
									onClick={() => setIsCollapsed(!isCollapsed)}
								/>
							) : undefined
						}
						style={{
							color: colors.blueAccent[333],
							margin: "10px 0 20px 0",
							// color: colors.blueAccent[111],
						}}
						// component={<Link to="/" />}
					>
						{!isCollapsed && (
							<Box
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								ml="10px"
							>
								<Typography
									variant="h5"
									onClick={() => {
										navigate("/adminDash");
									}}
								>
									Admin
								</Typography>

								<MenuOutlinedIcon
									onClick={() => setIsCollapsed(!isCollapsed)}
								/>
							</Box>
						)}
					</MenuItem>
					<Box
						// paddingLeft={isCollapsed ? undefined : "10%"}
						sx={{
							color: colors.blueAccent[333],
							// backgroundColor: "red",
						}}
					>
						{/* <Item
							title="Dashboard"
							to="/adminDash"
							icon={<HomeIcon />}
							selected={selected}
							setSelected={setSelected}
						/> */}
						<Item
							title="Manage Products"
							to="/manage"
							icon={<ShoppingCartIcon/>}
							selected={selected}
							setSelected={setSelected}
						/>
						<Item
							title="Order Details"
							to="/userDetails"
							icon={<Person2Icon />}
							selected={selected}
							setSelected={setSelected}
						/>
						<Item
							title="Card Details"
							to="/cardDetails"
							icon={<CreditCardIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
						<SubMenu
							defaultOpen
							label="Settings"
							icon={<SettingsIcon  />}
						>
						<Item
							title="Add User"
							to="/addUser"
							icon={<AddBoxIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
						<Item
							title="Reset Password"
							to="/resetPassword"
							icon={<LockIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
						</SubMenu>
						<Item
							title="Theme"
							to="/profile"
							icon={<AccountCircleIcon />}
							selected={selected}
							setSelected={setSelected}
						/>

						{/* <Item
							title="test"
							to="/test"
							icon={<AccountCircleIcon />}
							selected={selected}
							setSelected={setSelected}
						/> */}
						
						<MenuItem
								style={{
									color: colors.blueAccent[111],
								}}
								onClick={logout}
								icon={<PowerSettingsNewOutlinedIcon />}
							>
								<Typography sx={{fontSize: "12px",}}>
									Log Out
									</Typography>
							</MenuItem>
							
					</Box>
				</Menu>
			</Sidebar>
		</Box>
	);
};

export default SideNavBar;
