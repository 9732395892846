import { Box, Button, TextField, Typography } from "@mui/material";

import { tokens } from "./theme";
import { useState } from "react";
import Axios from "axios";
const host = "";

function ResetPassword() {

  const [currentPass, setCurrentPass] = useState("");
	const [error, setError] = useState("");
	const [newPass, setNewPass] = useState("");
	const [confirmPass, setConfirmPass] = useState("");
	const [showerr, setShowerr] = useState(true);


	const colors = tokens;
    const style = {
		width: "400px",
		height: "100%",
		borderRadius: 1,
		display: "grid",
		rowGap: 1,
		gridTemplateColumns: "repeat(1, 1fr)",
		boxShadow: 4,
		p: 5,
		m: "auto",
	};
	const style1 = {
		// width: "30%",
		// height: "100%",

		bgcolor: colors.primary[400],
		borderRadius: 1,
		display: "grid",
		columnGap: 1,
		rowGap: 1,
		gridTemplateColumns: "repeat(1, 1fr)",
		color: colors.blueAccent[800],
		boxShadow: "2px 2px 2px 2px #2e2f5f",

		p: "15px",
	};
	const updatePass = (e) => {
		// console.log(currentPass, newPass, confirmPass);
		e.preventDefault();
		if (newPass !== confirmPass) {
			setError("New Password does not match with Confirm Password");
			setShowerr(true);
		} else {
			const data = {
				currentPass: currentPass,
				newPass: newPass,
			};
			const url = host + "/updatePass";
			Axios.post(url, data).then((response) => {
				// console.log(response);
				if (response.data.msg === "Wrong Credentials") {
					setError("Incorrect Password!");
					setShowerr(true);
				} else if (response.data.msg === "success") {
					setError("Password Updated Successfully");
					setShowerr(true);
				}
			});
		}
	};

	return (
		// <Box m="20px">
		<Box sx={{ margin: "auto", width: "100%", hieght: "80%", mt: "100px" }}>

				<form onSubmit={updatePass}>
					<Box sx={style}>
						<TextField
							required
              variant="standard"
							id="cur_pass"
							label="Current Password"
							type="password"
							onChange={(e) => {
								setCurrentPass(e.target.value);
								setShowerr(false);
							}}
						/>
						<TextField
							required
              variant="standard"
							id="new_pass"
							label="New Password"
							type="password"
							onChange={(e) => {
								setNewPass(e.target.value);
								setShowerr(false);
							}}
						/>
						<TextField
							required
              variant="standard"
							id="confirm_pass"
							label="Confirm New Password"
							type="password"
							onChange={(e) => {
								setConfirmPass(e.target.value);
								setShowerr(false);
							}}
						/>
						<Button
							variant="contained"
							sx={{
								"background": colors.blueAccent[222],
								"height": "50px",
								":hover": { bgcolor: colors.themeBlue[222] },
							}}
							type="submit"
						>
							Change Password
						</Button>
            				{showerr ? (
					<Box m="auto">
						<Typography
							variant="h6"
							color={colors.redAccent[500]}
							fontWeight="bold"
						>
							{error}{" "}
						</Typography>
					</Box>
				) : (
					""
				)}
					</Box>
				</form>

	</Box>
		// </Box>
	);
};

export default ResetPassword;