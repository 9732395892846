import { Box, Button, TextField, Typography} from "@mui/material";
import useAuth from "./useAuth.js";
import { useState,  } from "react";
import { tokens } from "./theme";
const Login = () => {


	const colors = tokens;
	const { login } = useAuth();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [showerr, setShowerr] = useState(false);
	const handleLogin = (e) => {
		e.preventDefault();
		login(email, password).then((res) => {
			if (res === "fail") {
				setShowerr(true);
			}
		});
	};
	const style = {
		width: "400px",
		height: "100%",
		borderRadius: 1,
		display: "grid",
		columnGap: 1,
		rowGap: 2,
		gridTemplateColumns: "repeat(1, 1fr)",
		boxShadow: 4,
		p: 5,
		m: "auto",
	};
	return (
		<Box sx={{ margin: "auto", width: "100%", hieght: "80%", mt: "25px" }}>
			<form onSubmit={handleLogin}>
				<Box sx={style}>
				
					<TextField
						required
						variant="filled"
						
						label="User Name"
						onChange={(e) => {
							setEmail(e.target.value);
							setShowerr(false);
						}}
						value={email}
					/>
					<TextField
						required
						variant="filled"
						type="password"
						label="Password"
						onChange={(e) => {
							setPassword(e.target.value);
							setShowerr(false);
						}}
						value={password}
					/>
					{showerr ? (
						<Box m="auto">
							<Typography
								fontSize="16px"
								color={colors.redAccent[500]}
								fontWeight="bold"
							>
								Invalid Credentials
							</Typography>
						</Box>
					) : (
						""
					)}


					<Button
						type="submit"
						variant="contained"
						sx={{
							background: colors.blueAccent[222],
							height: "50px",

							":hover": { bgcolor: colors.themeBlue[100] },
						}}
					>
						Login
					</Button>
				</Box>
			</form>
		</Box>
	);
};

export default Login;
