// color design tokens export
export const tokens = {
	grey: {
		101: "#000000",
		100: "#141414",
		200: "#292929",
		300: "#3d3d3d",
		400: "#525252",
		500: "#666666",
		600: "#858585",
		700: "#a3a3a3",
		800: "#c2c2c2",
		900: "#e0e0e0",
		901: "#ffffff",
	},
	primary: {
		100: "#040509",
		200: "#080b12",
		300: "#0c101b",
		400: "#f2f0f0", // manually changed
		500: "#141b2d",
		600: "#1F2A40",
		700: "#727681",
		800: "#a1a4ab",
		900: "#d0d1d5",
	},
	greenAccent: {
		100: "#0f2922",
		200: "#1e5245",
		300: "#2e7c67",
		400: "#3da58a",
		500: "#4cceac",
		600: "#70d8bd",
		700: "#94e2cd",
		800: "#b7ebde",
		900: "#dbf5ee",
	},
	redAccent: {
		100: "#2c100f",
		200: "#58201e",
		300: "#832f2c",
		400: "#af3f3b",
		500: "#db4f4a",
		600: "#e2726e",
		700: "#e99592",
		800: "#f1b9b7",
		900: "#f8dcdb",
	},
	themeBlue: {
		100: "#1c5e79",
		200: "#357088",
		300: "#4e8297",
		400: "#6794a6",
		500: "#81a6b5",
		600: "#9ab7c3",
		700: "#b3c9d2",
		800: "#ccdbe1",
		900: "#e5edf0",
	},
	blueAccent: {
		111: "#2e2f5f",
		222: "#034d6b",
		333: "#133542",
		100: "#151632",
		200: "#2a2d64",
		300: "#3e4396",
		400: "#535ac8",
		500: "#6870fa",
		600: "#868dfb",
		700: "#a4a9fc",
		800: "#c3c6fd",
		900: "#e1e2fe",
	},
	rgba: {
		1080: "rgba(0, 0, 0, 0.80)",
		1130: "rgba(255, 255, 255, 0.30)",
		1115: "rgba(255, 255, 255, 0.15)",
		2070: " rgba(51, 92, 129, 0.70)",
	},
};
