import { Button, Typography, TextField, Box } from "@mui/material";
import { useState } from "react";
import Axios from "axios";
const DeleteProduct = ({ row, colors }) => {
	const [error, setError] = useState("");
	const [showerr, setShowerr] = useState(false);
	const delProduct = (e) => {
		e.preventDefault();
		const data = {
			id:row.id,
			product_name: e.target.product_name.value,
			rating: e.target.rating.value,
			mrp: e.target.mrp.value,
			price: e.target.price.value,
			tech_details: e.target.tech_details.value,
		}
		const url = "/delProduct";
		Axios.post(url, data).then((response) => {
			if (response.data === "error") {
				setError("ERROR! Something went wrong.");
				setShowerr(true);
			} else if (response.data === "success") {
				window.location.reload(true);
			}
		});
	};
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "50%",
		height: "auto",

		background: colors.grey[901],
		borderRadius: 2,
		display: "grid",
		columnGap: 1,
		rowGap: 1,
		gridTemplateColumns: "repeat(1, 1fr)",
		boxShadow: 4,
		p: 4,
		m: 0,
	};
	return (
		<form onSubmit={delProduct}>
			<Box sx={style}>
			<Typography
							color={colors.blueAccent[222]}
							variant="h5"
							fontWeight="bold"
							sx={{ mb: "5px" }}
						>
				Edit Product
			</Typography>
			<TextField variant="filled" id="product_name" label="Product Name" 
				defaultValue={row? row.product_name : ""} />					
			<TextField type="number" variant="filled" id="rating" label="Rating" 
				InputProps={{inputProps:{min:0, max:5}}} 
				defaultValue={row? row.rating : ""}/>
			<TextField variant="filled" id="mrp" label="MRP" 
				defaultValue={row? row.mrp : ""}/>
			<TextField variant="filled" id="price" label="Price" 
				defaultValue={row? row.price : ""}/>
			<TextField variant="filled" id="tech_details" label="Technical Details" 
				defaultValue={row? row.tech_details : ""}/>
				
				{showerr ? (
					<Typography
						variant="h6"
						color={colors.redAccent[500]}
						fontWeight="bold"
					>
						{error}
					</Typography>
				) : (
					""
				)}
				<Button
					variant="contained"
					type="submit"
					sx={{
						"background": colors.redAccent[300],
						"height": "50px",

						":hover": { bgcolor: colors.redAccent[400] },
					}}
				>
					Delete Product
				</Button>
			</Box>
		</form>
	);
};

export default DeleteProduct;
