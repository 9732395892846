import { Box, TextField, Button, Typography } from "@mui/material";
import { useState } from "react";
import Axios from "axios";
import { tokens } from "./theme";
const host = "";
function AddUser() {


	const colors = tokens;
	const [error, setError] = useState("");
	const [comPass, setComPass] = useState("");
	const [password, setPassword] = useState("");
	const [name, setName] = useState("");
	const [showerr, setShowerr] = useState(false);

    const handleRegister = (e) => {
		e.preventDefault();
        if(comPass===password){
            
		return new Promise((res) => {
			const url = host + "/register";
			Axios.post(url, {
				name: name,
				password: password,
			}).then((response) => {
				// console.log(response.data);
				if (response.data === "error") {
					setError(
						"ERROR! User may have been already registered, Please try again with different username"
					);
					setShowerr(true);
				} else if (response.data === "success") {
					setError(
						"User Created Sucessfully"
					);
					setShowerr(true);
				}
			});
		});
        }
        else{
            setError(
						"Passwords dont match"
					);
					setShowerr(true);
        }
	};

    const style = {
		width: "400px",
		height: "100%",
		borderRadius: 1,
		display: "grid",
		rowGap: 1,
		gridTemplateColumns: "repeat(1, 1fr)",
		boxShadow: 4,
		p: 5,
		m: "auto",
	};

  return (
   <Box sx={{ margin: "auto", width: "100%", hieght: "80%", mt: "100px" }}>
			<form onSubmit={handleRegister}>
				<Box sx={style}>
					
					<TextField
						required
                        variant="standard"
						id="name"
						label="User Name"
						// value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					<TextField
						required
                        variant="standard"
						id="password"
						label="Password"
						type="password"
						onChange={(e) => setPassword(e.target.value)}
					/>
					<TextField
						required
                        variant="standard"
						id="comf_password"
						label="Confirm Password"
						type="password"
						onChange={(e) => setComPass(e.target.value)}
                        
					/>

					{showerr ? (
						<Typography
							variant="h6"
							color={colors.redAccent[500]}
							fontWeight="bold"
						>
							{error}{" "}
						</Typography>
					) : (
						""
					)}
					<Button
						type="submit"
						variant="contained"
						sx={{
							background: colors.blueAccent[222],
							height: "50px",

							":hover": { bgcolor: colors.themeBlue[100] },
						}}
					>
						Create New Account
					</Button>
				</Box>
			</form>
		</Box>
  );
}

export default AddUser;