import Header from "./Header";
import Footer from "./Footer";
import "./Address.css"
import React, { useState, useEffect } from 'react';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { Button, Box, Typography } from "@mui/material";
import Radio from '@mui/material/Radio';
import spritImg from "./Images/sprite-map._CB443317321_.png"

import Card from "./Card"
import { tokens } from "./theme";
function Address() {
  const colors = tokens;
  const [step, setStep] = useState(1);
  const [country, setCountry] = useState('India');
  const [region, setRegion] = useState('Assam');
  const [name, setName] = useState('Assam');
  const [mobile, setMobile] = useState('Assam');
  const [address, setAddress] = useState('Assam');

  useEffect(() => {
        window.scrollTo(0, 0)
    }, [step]);


  const nextStep = (e)=>{
    e.preventDefault();
    const tempAdd = e.target.flat.value + ", " + e.target.area.value + ", " + e.target.landmark.value +", " + 
      e.target.city.value + ", " + e.target.pin.value + ", " + region + ", " + country
    setName(e.target.name.value)
    setMobile(e.target.mobile.value)
    setAddress(tempAdd)
    setStep(2)
  }

  return (
   <div >
    
		<Header />
      <div className="FormApp" style={{minWidth:'350px', width:"50%", margin:'auto'}}>
        
          {step===1?
        <form onSubmit={nextStep}>
          
          <div style={{display:"flex", flexDirection:"column", height:"auto", margin:'20px', rowGap:'10px'}}>

            <h5 style = {{color:'grey'}}>Your Account › Your Addresses › <span style={{color:"rgb(251, 100, 27)"}}>New Address</span></h5>
            <h3>Add a new address</h3>

            <h5 className="add__title">Country/Region</h5>         
              <CountryDropdown
                value={country}
                onChange={(val) => setCountry(val)} style={{width:"100%", height:'25px', borderRadius:'5px'}}/>

            <h5 className="add__title">Full name (First and Last name)</h5>
            <input required  type="text" id="name" className="add__input"/>

            <h5 className="add__title">Mobile number</h5>
            <input required  type="number" id="mobile" className="add__input"/>

            <h5 className="add__title">Pincode</h5>
            <input required  type="number" id="pin" className="add__input"/>

            <h5 className="add__title">Flat, House no., Building, Company, Apartment</h5>
            <input required  type="text" id="flat" className="add__input"/>

            <h5 className="add__title">Area, Street, Sector, Village</h5>
            <input required  type="text" id="area" className="add__input"/>

            <h5 className="add__title">Landmark</h5>
            <input required  type="text" id="landmark" className="add__input"/>

            <div style={{display:'flex',  height:'50px', columnGap:'10px'}}>

              <div style={{width:"50%"}}>
                <h5 className="add__title1">Town/City</h5>
                  <input required  type="text" id="city" className="add__input"/>
              </div>

              <div style={{width:"50%"}}>                 
                  <h5 className="add__title1">State</h5>
                <RegionDropdown
                country={country}
                value={region}
                onChange={(val) => setRegion(val)} 
                style={{width:"100%", height:'25px', borderRadius:'5px'}}
                />

              </div>
            </div>
            

            

           
            <Button
                variant="contained"
                type="submit"
                sx={{
                    "background": "#FFD814",
                    "height": "40px",
                    "color":"black",
                    "width":"100%",
                    "textTransform":'none',
                    "borderRadius":'5px',

                    ":hover": { bgcolor: "#eca63d" },
                }}
            >
                <p style={{fontSize:'12px'}}>Add Address</p>
            </Button> 
          
          </div>
          </form>
            :step===2?
            <div>
              <div style={{display:'flex', justifyContent:'space-between', marginTop:'20px', borderBottom:'1px solid grey', padding:'20px'}}>
                <h3>Dilevery address:</h3>
                <span style={{color:'#545f6a',  width:'250px'}}>
                  <h5 style={{}}>{name}</h5>
                  <h5>{address}</h5>
                </span>
                <h5 onClick={()=>setStep(1)} style={{color:'#2454ce', cursor:'pointer', width:'80px'}}>change</h5>
              </div>
                <h3 style={{color:"rgb(251, 100, 27)", padding:'20px'}}>Select a payment method:</h3>
              <div style={{marginTop:'0px', border:'1px solid #caced3', padding:'20px', borderRadius:'5px'}}>

                
                <div style={{display:'flex', borderBottom:'1px solid #caced3'}}>
                  <Radio color="primary" checked='true'/>
                  <div>
                    <h4>Add Debit / Credit / ATM Card</h4>
                    <div style={{margin:'auto', display:'flex', paddingBottom:'20px' }}>
                        <span style={{display: 'block', height:'30px', width: '45px', marginRight:'6px', backgroundImage: `url(${spritImg})`, backgroundPosition:'0px'}}/>
                        <span style={{display: 'block', height:'30px', width: '45px', marginRight:'6px', backgroundImage: `url(${spritImg})`, backgroundPosition:'-45px'}}/>
                        <span style={{display: 'block', height:'30px', width: '45px', marginRight:'6px', backgroundImage: `url(${spritImg})`, backgroundPosition:'-90px'}}/>
                        {/* <span style={{display: 'block', height:'30px', width: '45px', marginRight:'6px', backgroundImage: `url(${spritImg})`, backgroundPosition:'-135px'}}/> */}
                        <span style={{display: 'block', height:'30px', width: '45px', marginRight:'6px', backgroundImage: `url(${spritImg})`, backgroundPosition:'-315px'}}/>
                        <span style={{display: 'block', height:'30px', width: '45px', marginRight:'6px', backgroundImage: `url(${spritImg})`, backgroundPosition:'-585px'}}/>
                        <span style={{display: 'block', height:'30px', width: '45px', marginRight:'6px', backgroundImage: `url(${spritImg})`, backgroundPosition:'-810px'}}/>
                    
                    </div>
                  
                  </div>
                </div >

                <div style={{display:'flex', borderBottom:'1px solid #caced3', margin:'auto'}}>
                 
                  <Radio color="primary" disabled/> 
                  <div style={{paddingBottom:'20px', marginTop:'20px' }}>
                    <h4 style={{color:'#879097'}}>EMI Unavailable</h4>
                  </div>
                </div>

                <div style={{display:'flex', }}>
                 
                  <Radio color="primary" disabled/> 
                  <div style={{ marginTop:'20px' }}>
                    <h4 style={{color:'#879097'}}> Cash on Delivery/Pay on Delivery</h4>
                    <h5 style={{color:'#879097'}}> Unavailable for this payment</h5>
                    </div>
                </div>

              </div>

                <Button
                variant="contained"
                sx={{
                    "background": "#FFD814",
                    "height": "40px",
                    "color":"black",
                    "width":"100%",
                    "textTransform":'none',
                    "borderRadius":'5px',
                    "marginTop":'20px',
                    "marginBottom":'20px',

                    ":hover": { bgcolor: "#eca63d" },
                }}
                onClick={()=>{setStep(3)}}
            >
               Continue
            </Button> 
            </div>
            :step===3?
            <Card setStep={setStep} 
              name={name} 
              mobile={mobile}
              address={address}/>:""}
          


       

        
        
      </div>
      <Footer/>
		</div>
  );
}

export default Address;