import Axios from "axios";
import * as React from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { useState } from "react";
const authContext = React.createContext();
const host = "";

function useAuth() {
	const [authed, setAuthed] = useState(null);
	const [user, setUser] = useState(null);
	let navigate = useNavigate();
	return {
		authed,
		user,
		Auth({ children }) {
			// Axios.get("http://localhost:5000/token", {}).then((response) => {

			const url = host + "/token";
			Axios.get(url).then((response) => {
				// console.log(response);
				if (response.data.accessToken) {
					setUser(response.data.name);
					setAuthed(true);
				} else {
					setAuthed(false);
				}
			});

			return authed == null ? null : authed ? (
				children
			) : (
				<Navigate to={"/login"} />
			);
		},
		logout() {
			return new Promise((res) => {
				const url = host + "/logout";
				Axios.delete(url).then((response) => {
					setAuthed(false);
					localStorage.removeItem("user");
					navigate("/login");
				});
			});
		},
		login(email, password) {
			return new Promise((res) => {
				// Axios.post("http://localhost:5000/login", {
				const url = host + "/login";
				Axios.post(url, {
					email: email,
					password: password,
				}).then((response) => {
					// console.log(response);
					if (response.data.accessToken) {
						window.localStorage.setItem(
							"user",
							JSON.stringify(response.data.data)
						);
						setAuthed(true);
						navigate("/manage");
					} else {
						// setUser(response.data.message);
						res("fail");
					}
				});
			});
		},
	};
}
export function AuthProvider({ children }) {
	const auth = useAuth();

	return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
	return React.useContext(authContext);
}
