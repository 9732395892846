import Test  from "./Test"
import {
	Box
} from "@mui/material";

function AddImg({colors, row}) {
        const style = {
		position: "absolute",
		top: "0%",
		left: "50%",
		transform: "translate(-50%, -0%)",
		width: "50%",
		height: "auto",
		background: colors.grey[901],
		borderRadius: 2,
		display: "grid",
		columnGap: 1,
		rowGap: 1,
		gridTemplateColumns: "repeat(1, 1fr)",
		p: 4,
		m: 0,
	};

  return (
   <Box sx={style}>
		Main Image:<Test id = {row.id} imageType = "main_img" />
		Banner Image:<Test id = {row.id} imageType = "banner_img" />
        carousel  Image 1:<Test id = {row.id} imageType = "car_img1" />
        carousel  Image 2:<Test id = {row.id} imageType = "car_img2" />
        carousel  Image 3:<Test id = {row.id} imageType = "car_img3" />
        carousel  Image 4:<Test id = {row.id} imageType = "car_img4" />
        
	</Box>
  );
}

export default AddImg;