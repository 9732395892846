export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#e0e0e0",
         
        },
        primary: {
          100: "#d0d1d5",
        
        },
        redAccent: {
          500: "#db4f4a",
         
        },
        buttonMain: {
          100: "#febd69",
         
        },
        themeMain: {
          100: "#131921",
         
        },
      }
    : {
        grey: {
          100: "#141414",
         
        },
        primary: {
          100: "#040509",
        
        },
        redAccent: {
          500: "#db4f4a",
         
        },
        buttonMain: {
          100: "#ffffff",
         
        },
        themeMain: {
          100: "#2874f0",
        
        },
      }),
});