import "./Product.css";
import { Button, Box, Typography } from "@mui/material";
import { ToastContainer,toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Axios from "axios";
import { useState, useEffect } from "react";
import primeImg from "./Images/prime11.png"
import fa from "./Images/fa.png"
import loveImg from "./Images/love.png"
import { useNavigate } from "react-router-dom";
const host = "";
const Product = ({ row, isCollapsed }) => {
  const [theme, setTheme] = useState('')
  const [respImg, setRespImg] = useState();
  const navigate = useNavigate();

	useEffect(() => {
    const getImg = () => {

			const data = {
				id: row.id,
				imageType:"main_img"
			};

			const url = host + "/getImg";
			Axios.post(url, data, { responseType: "blob" }).then((response) => {
				if(response.data!==""){
					const file = window.URL.createObjectURL(
						new Blob([response.data], { type: "image/jpeg" })
					);
					// window.open(file);
					setRespImg(file);
				}
				else setRespImg('');
			});
	
	};
	getImg()
  }, []);

    useEffect(() => {

  const fetchTheme = ()=>{
    const url = host + "/fetchTheme";
    Axios.get(url).then((response) => {
      if(response.data[0].status==='active'){
        setTheme(response.data[0])       
      }
      else {
        setTheme(response.data[1])      
      }
      

    })
  }
  fetchTheme()
  }, []);

  return (
    <div className="product">
      <div style={{display:'flex', justifyContent:'space-between', width:'100%', }} >
      <Box sx = {{
                  // position:'absolute',
                  background: "#B12704",
                  borderRadius:'100%', 
                  display:'flex',
                  alignContent:'center',
                  width:isCollapsed?"20px":"42px",
                  }}>
                    <Typography sx = {{fontSize:isCollapsed?"6px":"12px",
                                        fontWeight:'bold',
                                        color:'white',
                                        padding:'2px',
                                        margin:'auto',
                                        }}>
                    {Math.trunc(100-row.price*100/row.mrp)}%<br/>OFF
                    </Typography>
          </Box>
          <Box sx = {{

                  borderRadius:'100%', 
                  display:'flex',
                  alignContent:'center',
                  width:isCollapsed?"20px":"42px",
                  padding:'2px',
                  }}>
                    <img
            width="30px"
            src={loveImg}
            style={{margin:'auto'}}
            alt=""
             />
          </Box>
        </div>
         <img src={respImg} alt={row?.product_name} style={{minHeight:"200px"}}/>
      <div className="product__info">
        <p className="product_title">{row?.product_name}</p>
        <p className="product__price">
          MRP: <small>₹</small>
          <strike>{row?.mrp}</strike>
          
        </p>
        <Typography sx = {{fontSize:'14px',
                                        fontStyle:'italic',
                                        color:'red',
                                        padding:'2px',
                                        margin:'auto',
                                        }}> Save&nbsp;<small>₹</small>
                    {(row.mrp - row.price)} 
                    </Typography>
        <img
            width="60px"
            src={theme?.id===1?primeImg:fa}
            alt=""
          />
        <p className="product__price">
          Price: <small>₹</small>
          <strong>{row?.price}</strong>
        </p>         
        <div className="product__rating">
          {Array(Number(row?.rating))
            .fill()
            .map((_) => (
              <p>⭐</p>
            ))}
        </div>
      </div>
      
      <Button sx={{height:"35px"}} onClick={() => {
										navigate(`/product?id=${row.id}`);
									}}>Buy Now</Button>
      <ToastContainer position="top-center" closeOnClick/>
    </div>
  );
};

export default Product;
