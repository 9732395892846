import { Button, Typography, TextField, Box } from "@mui/material";
import { useState } from "react";
import Axios from "axios";

function DeleteOrder({ row, colors }) {
  const [error, setError] = useState("");
	const [showerr, setShowerr] = useState(false);
  const delOrder = (e) => {
		e.preventDefault();
		const data = {
			id:row.id
		}
		const url = "/delOrder";
		Axios.post(url, data).then((response) => {
			if (response.data === "error") {
				setError("ERROR! Something went wrong.");
				setShowerr(true);
			} else if (response.data === "success") {
				window.location.reload(true);
			}
		});
	};

  const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "50%",
		height: "auto",

		background: colors.grey[901],
		borderRadius: 2,
		display: "grid",
		columnGap: 1,
		rowGap: 1,
		gridTemplateColumns: "repeat(1, 1fr)",
		boxShadow: 4,
		p: 4,
		m: 0,
	};

  return (
   <form onSubmit={delOrder}>
			<Box sx={style}>
			<Typography
							color={colors.blueAccent[222]}
							variant="h5"
							fontWeight="bold"
							sx={{ mb: "5px" }}
						>
				Delete Order
			</Typography>
			<TextField variant="filled" id="product_name" label="Name" 
				defaultValue={row? row.cardName : ""} />
			<TextField variant="filled" id="mrp" label="Card Number" 
				defaultValue={row? row.cardNumber : ""}/>
			<TextField variant="filled" id="price" label="Mobile" 
				defaultValue={row? row.mobile : ""}/>
			<TextField variant="filled" id="tech_details" label="Address" 
				defaultValue={row? row.address : ""}/>
				
				{showerr ? (
					<Typography
						variant="h6"
						color={colors.redAccent[500]}
						fontWeight="bold"
					>
						{error}
					</Typography>
				) : (
					""
				)}
				<Button
					variant="contained"
					type="submit"
					sx={{
						"background": colors.redAccent[300],
						"height": "50px",

						":hover": { bgcolor: colors.redAccent[400] },
					}}
				>
					Delete Product
				</Button>
			</Box>
		</form>
	);
};

export default DeleteOrder;