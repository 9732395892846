// import Navlinks from "./Navlinks";
import Header from "./Header";
import MainBody from "./MainBody";
import Footer from "./Footer";
function Home() {
  return (
    <div>
      <Header />
        {/* <Navlinks /> */}
        <MainBody />
        <Footer/>
     
    </div>
  );
}

export default Home;