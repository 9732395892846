import Countdown from 'react-countdown';
import { tokens } from "./theme";
import { useState, useRef} from 'react';

function CountdownTimer(){
    const colors = tokens;
    const clockRef = useRef()
    const [countTime, setCountTime] = useState(Date.now() + 1000*1000);
    const renderer = ({ hours, minutes, seconds, completed }) => {
    const Completionist = () => setCountTime(Date.now() + 1000*1000);
    if (completed) {
        // Render a completed state
        setCountTime(Date.now() + 1000*5);
        clockRef.current.start()

        // return <Completionist />;
    } else {
        // Render a countdown
        return <span style={{margin:'auto',}}>Offer Ends in <span style={{margin:'auto',fontWeight:'bold',fontSize:'18px', color:"#B12704"}}>{minutes} min {seconds} sec</span></span>;
    }
    };

    return <Countdown
                          date={countTime}
                          renderer={renderer}
                          ref={clockRef}
                        />
}
export default CountdownTimer