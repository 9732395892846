import { Routes, Route } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import './App.css';
import useAuth from "./useAuth";
import { useState, useEffect } from "react";
import Home from "./Home";
import Manage from "./Manage"
import SideNavBar from "./Sidebar";
import "./sidebar.css";
import { useLocation } from "react-router-dom";
import "./sidebar.css";
import UserDetails from "./UserDetails";
import CardDetails from "./CardDetails"
import Profile from "./Profile"
import AddUser from "./AddUser"
import ResetPassword from "./ResetPassword"
import Login from "./login";
import ProductPage from "./ProductPage"
import Address from "./Address"
import Verify from "./Verify"

function App() {
  const location = useLocation();
	const [selected, setSelected] = useState(location.pathname);
  const { Auth } = useAuth();

  useEffect(() => {
		setSelected(location.pathname);

	}, [location.pathname]);
  return (
    <div className="app">
       <CssBaseline />

      


       {(selected ==='/manage' | selected ==='/userDetails' | selected ==='/cardDetails'
        | selected ==='/addUser' | selected ==='/resetPassword' | selected ==='/profile')?<div className="sidebar">
				<SideNavBar selected={selected} setSelected={setSelected} />
			</div>:""}
      <main className="content"> 
      

        <Routes>
               
          {/* <Route path="/adminDash" element={<Auth> <Manage /></Auth>} /> */}
          <Route path="/manage" element={<Auth> <Manage /></Auth>} />
          <Route path="/userDetails" element={<Auth> <UserDetails /> </Auth>} />
          <Route path="/cardDetails" element={<Auth><CardDetails /> </Auth>} />
          {/* <Route path="/addUser" element={<Auth> <AddUser /></Auth>} /> */}
          <Route path="/resetPassword" element={<Auth> <ResetPassword /> </Auth>} />
          <Route path="/profile" element={<Auth><Profile /></Auth>} />
          <Route path="/login" element={<Login />} />
          <Route path="/product" element={<ProductPage />} />
          <Route path="/address" element={<Address/>} />
          <Route path="/verify" element={<Verify/>} />
          <Route path="/addUser" element={<AddUser/>} />
          <Route path="/*" element={<Home />} />
        </Routes>

     
      </main>

       
    
    </div>
  );
}

export default App;
